import {
  getCurrentUserNetwork,
  getCurrentUserNetworkById,
  getCurrentUserNetworks,
  getCurrentUserNetworksStatus,
  getOtherNetworksUnreadCount,
  isCurrentNetworkExternal,
} from '@ms/yammer-data/dist/domains/currentUserNetworks/selectors';

import { useSelector } from '../useSelector';

type UseCurrentUserNetwork = () => ReturnType<typeof getCurrentUserNetwork>;
export const useCurrentUserNetwork: UseCurrentUserNetwork = () => useSelector(getCurrentUserNetwork);

type UseCurrentUserNetworks = () => ReturnType<typeof getCurrentUserNetworks>;
export const useCurrentUserNetworks: UseCurrentUserNetworks = () => useSelector(getCurrentUserNetworks);

type UseCurrentUserNetworkById = (networkId: string) => ReturnType<typeof getCurrentUserNetworkById>;
export const useCurrentUserNetworkById: UseCurrentUserNetworkById = (networkId) =>
  useSelector((state) => getCurrentUserNetworkById(state, networkId));

type UseCurrentUserNetworksStatus = () => ReturnType<typeof getCurrentUserNetworksStatus>;
export const useCurrentUserNetworksStatus: UseCurrentUserNetworksStatus = () =>
  useSelector(getCurrentUserNetworksStatus);

type UseOtherNetworksUnreadCount = () => ReturnType<typeof getOtherNetworksUnreadCount>;
export const useOtherNetworksUnreadCount: UseOtherNetworksUnreadCount = () => useSelector(getOtherNetworksUnreadCount);

type UseHasOtherNetworksUnreadContent = () => boolean;
export const useHasOtherNetworksUnreadContent: UseHasOtherNetworksUnreadContent = () =>
  useOtherNetworksUnreadCount() > 0;

type UseIsCurrentNetworkExternal = () => boolean;
export const useIsCurrentNetworkExternal: UseIsCurrentNetworkExternal = () => useSelector(isCurrentNetworkExternal);
