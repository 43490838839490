import { CurrentUserNetworkFields, NetworkType } from '@ms/yammer-graphql';

import { CurrentUserNetworkList, EntityStatus, YammerState, getStatus } from '../../state/types';
import { getCurrentNetwork } from '../networks/selectors';

type GetCurrentUserNetworks = (state: YammerState) => CurrentUserNetworkList | undefined;
export const getCurrentUserNetworks: GetCurrentUserNetworks = (state: YammerState) =>
  state.currentUserNetworks.fulfilled;

type GetCurrentUserNetworkById = (state: YammerState, networkId: string) => CurrentUserNetworkFields | undefined | null;
export const getCurrentUserNetworkById: GetCurrentUserNetworkById = (state, networkId) => {
  if (!networkId) {
    return;
  }

  const currentUserNetworks = getCurrentUserNetworks(state);

  return currentUserNetworks
    ? Object.values(currentUserNetworks).filter(({ node: { id } }: CurrentUserNetworkFields) => id === networkId)[0]
    : undefined;
};

type GetCurrentUserNetworksStatus = (state: YammerState) => EntityStatus;
export const getCurrentUserNetworksStatus: GetCurrentUserNetworksStatus = (state) =>
  getStatus(state.currentUserNetworks);

type GetCurrentUserNetwork = (state: YammerState) => CurrentUserNetworkFields | undefined | null;
export const getCurrentUserNetwork: GetCurrentUserNetwork = (state) => {
  const { id: currentNetworkId } = getCurrentNetwork(state);

  return getCurrentUserNetworkById(state, currentNetworkId);
};

type GetOtherNetworksUnreadCount = (state: YammerState) => number;
export const getOtherNetworksUnreadCount: GetOtherNetworksUnreadCount = (state: YammerState) => {
  const currentUserNetworks = state.currentUserNetworks.fulfilled;

  return currentUserNetworks && currentUserNetworks.length > 0
    ? currentUserNetworks
        .filter((network) => !network?.isCurrent)
        .map((network) => (network ? network.viewerUnreadContentCount : 0))
        .reduce((sum, current) => sum + current, 0)
    : 0;
};

type IsCurrentNetworkPrimary = (state: YammerState) => boolean;
export const isCurrentNetworkPrimary: IsCurrentNetworkPrimary = (state) => {
  const currentUserNetwork = getCurrentUserNetwork(state);

  return !!currentUserNetwork?.isPrimary;
};

type IsCurrentNetworkExternal = (state: YammerState) => boolean;
export const isCurrentNetworkExternal: IsCurrentNetworkExternal = (state) => {
  const currentUserNetwork = getCurrentUserNetwork(state);

  return currentUserNetwork?.networkType === NetworkType.EXTERNAL;
};
