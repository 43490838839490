import { useIsCurrentNetworkExternal } from '../currentUserNetworks';
import { useCurrentSessionUser } from '../session';

type UseShowStorylineAdminSettings = () => boolean;
export const useShowStorylineAdminSettings: UseShowStorylineAdminSettings = () => {
  const { isNetworkAdmin } = useCurrentSessionUser();
  const isNetworkExternal = useIsCurrentNetworkExternal();

  return isNetworkAdmin && !isNetworkExternal;
};
