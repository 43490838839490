import { useExperimentTreatmentWithoutReporting } from '@ms/yammer-hooks/dist/experiments';
import { lazyLoadOnRender } from '@ms/yammer-libs-lazy';
import React, { FC } from 'react';

const loader = () => import(/* webpackChunkName: "4-feat-perf-hud" */ './DogfoodPerfHUD');

const LazyLoadOnRenderLoadablePerfHUD = lazyLoadOnRender({ loader });

export const LoadableDogfoodPerfHUD: FC = () => {
  const isEnabled = useExperimentTreatmentWithoutReporting('DogfoodPerfHUD') === true;

  return isEnabled ? <LazyLoadOnRenderLoadablePerfHUD /> : null;
};
